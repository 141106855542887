import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createTicketType, createTicketTypeFailure, 
  createTicketTypeSuccess, deleteTicketType, deleteTicketTypeFailure,
  deleteTicketTypeSuccess, filterTicketJobTitleAssignments, filterTicketJobTitleAssignmentsSuccess, filterTicketRoleAssignments, filterTicketRoleAssignmentsSuccess, filterTicketUserAssignments, filterTicketUserAssignmentsSuccess,
  getViewTypes, getViewTypesSuccess, loadTicketType, loadTicketTypeFailure, loadTicketTypes, 
  loadTicketTypesFailure, loadTicketTypesSuccess, loadTicketTypeSuccess, reorderStatuses, 
  reorderStatusesFailure, reorderStatusesSuccess, saveTicketJobTitleAssignments, saveTicketRoleAssignments, saveTicketUserAssignments, saveTicketUserAssignmentsSuccess, updateTicketType, updateTicketTypeFailure, 
  updateTicketTypeSuccess } from './ticket-types.actions';
import { TicketTypesService } from '../../services/ticket-types.service';
import { Store } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable()
export class TicketTypesEffects {
    constructor(private actions$: Actions,
      private ticketTypesService: TicketTypesService,
      private alertService: AlertService,
      private store: Store<State>,
      private router: Router,
    ) { }

  getState(store: Store<State>): State {
    let state: State;

    store.pipe(take(1)).subscribe(s => state = s);

    return state;
  }

  loadTicketTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadTicketTypes),
    mergeMap(() => this.ticketTypesService.getAllTicketTypes().pipe(
      map(ticketTypes => loadTicketTypesSuccess({ ticketTypes })),
      catchError(error => of(loadTicketTypesFailure({ error })))
    ))
  ));

  loadTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(loadTicketType),
    mergeMap(action => this.ticketTypesService.getTicketTypeById(action.id).pipe(
      map(ticketType => loadTicketTypeSuccess({ ticketType })),
      catchError(error => of(loadTicketTypeFailure({ error })))
    ))
  ));

  createTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(createTicketType),
    mergeMap(action => this.ticketTypesService.createTicketType(action.ticketType).pipe(
      map(ticketType => createTicketTypeSuccess({ ticketType })),
      catchError(error => of(createTicketTypeFailure({ error })))
    ))
  ));

  updateTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(updateTicketType),
    mergeMap(action => this.ticketTypesService.updateTicketType(action.id, action.ticketType).pipe(
      map(() => updateTicketTypeSuccess()),
      catchError(error => of(updateTicketTypeFailure({ error })))
    ))
  ));
    
    deleteTicketType$ = createEffect(() => this.actions$.pipe(
      ofType(deleteTicketType),
      mergeMap(action => this.ticketTypesService.deleteTicketType(action.id).pipe(
        map(() => deleteTicketTypeSuccess()),
        catchError(error => of(deleteTicketTypeFailure({ error })))
      ))
    ));
  
    reorderStatuses$ = createEffect(() => this.actions$.pipe(
      ofType(reorderStatuses),
      mergeMap(action => this.ticketTypesService.reorderStatuses(action.id, action.startIndex, action.newIndex).pipe(
        map((statuses) => reorderStatusesSuccess({ statuses: statuses })),
        catchError(error => of(reorderStatusesFailure({ error })))
      ))
    ));
  
    getViewTypes$ = createEffect(() => this.actions$.pipe(
      ofType(getViewTypes),
      mergeMap(() => this.ticketTypesService.getViewTypes().pipe(
        map(viewTypes => getViewTypesSuccess({ viewTypes }))
      ))
    ));
  
    filterTicketUserAssignments$ = createEffect(() => this.actions$.pipe(
      ofType(filterTicketUserAssignments),
      mergeMap((action) => this.ticketTypesService.filterTicketUserAssignments(action.filter).pipe(
        map(userAssignments => filterTicketUserAssignmentsSuccess({ userAssignments }))
      ))
    ));
  
    filterTicketRoleAssignments$ = createEffect(() => this.actions$.pipe(
      ofType(filterTicketRoleAssignments),
      mergeMap((action) => this.ticketTypesService.filterTicketRoleAssignments(action.filter).pipe(
        map(roleAssignments => filterTicketRoleAssignmentsSuccess({ roleAssignments }))
      ))
    ));
      
    filterTicketJobTitleAssignments$ = createEffect(() => this.actions$.pipe(
      ofType(filterTicketJobTitleAssignments),
      mergeMap((action) => this.ticketTypesService.filterTicketJobTitleAssignments(action.filter).pipe(
        map(jobTitleAssignments => filterTicketJobTitleAssignmentsSuccess({ jobTitleAssignments }))
      ))
    ));
      
    saveTicketUserAssignments$ = createEffect(() => this.actions$.pipe(
      ofType(saveTicketUserAssignments),
      mergeMap((action) => this.ticketTypesService.saveTicketUserAssignments(action.users).pipe(
        map(userAssignments => saveTicketUserAssignmentsSuccess({ userAssignments }))
      ))
    ));
  
    saveTicketRoleAssignments$ = createEffect(() => this.actions$.pipe(
      ofType(saveTicketRoleAssignments),
      mergeMap((action) => this.ticketTypesService.saveTicketRoleAssignments(action.roles).pipe(
        map(roleAssignments => filterTicketRoleAssignmentsSuccess({ roleAssignments }))
      ))
    ));
      
    saveTicketJobTitleAssignments$ = createEffect(() => this.actions$.pipe(
      ofType(saveTicketJobTitleAssignments),
      mergeMap((action) => this.ticketTypesService.saveTicketJobTitleAssignments(action.jobTitles).pipe(
        map(jobTitleAssignments => filterTicketJobTitleAssignmentsSuccess({ jobTitleAssignments }))
      ))
    ));
}
