import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { deepClone, downloadFile, getDateWithOffset, getShortDateFormatted } from '../../../../helpers/utils';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import {
  exportBenefitPlanEDITransmissionData, exportBenefitPlanEDITransmissionDataSuccess,
  getBenefitPlanEDITransmissionMembers, getBenefitPlanEDITransmissionMembersSuccess,
  putBenefitPlanEDITransmissionSuccess, putBenefitPlanEDITransmission
} from '../../../../core/store/benefitPlanEDI/benefitPlanEDI.action';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { BenefitPlanEDITransmissionModel } from '../../../../models/benefitPlanEDITransmissionModel';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BenefitPlanEDIMemberInformationModel } from '../../../../models/benefitPlanEDIMemberInformationModel';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-transmission-benefitPlanEDI',
  templateUrl: './transmission-benefitPlanEDI.component.html',
  styleUrls: ['./transmission-benefitPlanEDI.component.scss']
})
 
export class TransmissionBenefitPlanEDIComponent extends BaseComponent implements OnInit {
  filter: any = {};
  list: PagingResultsModel<BenefitPlanEDIMemberInformationModel>;
  filteredList: BenefitPlanEDIMemberInformationModel[] = [];

  loadingList: boolean = true;
  exportingExcel: boolean = false;
  uploadingFile: boolean = false;


  filterType: string = "Current";
  dateSent: Date = null;
  filename: string = null;
  transmissionId: number = null;
  carrier: string = null;
  

  
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,) {
    super();
  }

  ngOnInit() {
    this.subs.push(

      this.store.select(s => s.benefitPlanEDIState.loadingTransmissionMembers).subscribe(loading => {
        this.loadingList = loading || this.list == null;
      }),
      this.store.select(s => s.benefitPlanEDIState.exportingExcel).subscribe(loading => {
        this.exportingExcel = loading;
      }),
      this.store.select(s => s.benefitPlanEDIState.transmissionMembers).subscribe(list => {
        this.list = list;
        this.loadingList = false;
        this.filterTypeChanged();
      }),
      this.store.select(s => s.benefitPlanEDIState.uploadingFile).subscribe(loading => {
        this.uploadingFile = loading;
      }),
      this.actions$.pipe(
        ofType(putBenefitPlanEDITransmissionSuccess),
        tap((action) => {
          console.info(action);
          this.alertService.success(`File uploaded!
          ${action.transmission.description}
          ${action.transmission.notes}`);
          this.reloadList(this.filter);
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(exportBenefitPlanEDITransmissionDataSuccess),
        tap(action => {
          downloadFile(action.doc, (this.transmissionId > 0 && this.list?.additionalProperties?.fileName != null ? `${this.list?.additionalProperties?.fileName}.xlsx` : `${this.carrier}Subscribers.xlsx`));
        })
      ).subscribe()
    );


    this.route.params.subscribe(params => {
      if (params.carrier)
        this.carrier = params.carrier;
      if (params.transmissionId) {
        this.transmissionId = parseInt(params.transmissionId);
      }
    });
  }  


  reloadList(event) {
    this.filter = event;
    this.filter = deepClone(this.filter || { filters: {} });


    this.filter.filters.transmissionId = { Name: 'transmissionId', Value: { value: this.transmissionId } };
    this.filter.filters.carrier = { Name: 'carrier', Value: { value: this.carrier } };

    this.store.dispatch(getBenefitPlanEDITransmissionMembers({
      filter: this.filter
    }));
  }


  formatSSN(ssn: string) {
    if (ssn.length == 9)
      return ssn.substring(0,3) + '-' + ssn.substring(3,5) + '-' + ssn.substring(5,9);
    return ssn;
  }


  filterTypeChanged() {
    var cutOffDate = getDateWithOffset(-7);
    var today = getDateWithOffset(0);
    //this.filteredList = this.list?.result;
    if (this.list && this.list.result) {
      if (this.transmissionId > 0)
        this.filteredList = this.list?.result;
      else if (this.filterType == "Current")
        this.filteredList = this.list.result.filter(x =>
          new Date(x.benefitBeginDate) <= today && (!x.benefitEndDate || new Date(x.benefitEndDate) >= cutOffDate ));
      else if (this.filterType == "Removed")
        this.filteredList = this.list.result.filter(x => x.benefitEndDate && new Date(x.benefitEndDate) < cutOffDate); // && x.employmentEndDate != null
      else if (this.filterType == "OpenEnrolment")
        this.filteredList = this.list.result.filter(x => new Date(x.benefitBeginDate) > today);
      else
        this.filteredList = this.list?.result;
    }
  }
  filteredDependents(member: BenefitPlanEDIMemberInformationModel) {
    var cutOffDate = getDateWithOffset(-7);
    var today = getDateWithOffset(0);
    //this.filteredList = this.list?.result;

    if (!member || !member.dependents || member.dependents.length == 0)
      return [];

    
    if (this.transmissionId > 0)
      return member.dependents;

    else if (this.filterType == "Current")
      return member.dependents.filter(x =>  new Date(x.benefitBeginDate) <= today && (!x.benefitEndDate || new Date(x.benefitEndDate) >= cutOffDate));
    else if (this.filterType == "Removed")
      return member.dependents.filter(x => x.benefitEndDate && new Date(x.benefitEndDate) < cutOffDate); // && x.employmentEndDate != null
    else if (this.filterType == "OpenEnrolment")
      return member.dependents.filter(x => new Date(x.benefitBeginDate) > today);
    else
      return member.dependents;
  }

  exportToExcel() {

    this.store.dispatch(exportBenefitPlanEDITransmissionData({
      carrier: this.carrier,
      transmissionId: this.transmissionId||0
    }));
  }

  uploadFile() {

    this.store.dispatch(putBenefitPlanEDITransmission({
      carrier: this.carrier
    }));
  }


  getHealthCoverageInfo(subscriber: BenefitPlanEDIMemberInformationModel) {
    
    var coverages = [];
    if (subscriber && subscriber.healthCoverage && subscriber.healthCoverage.length) {

      var cutOffDate = getDateWithOffset(-7);
      var today = getDateWithOffset(0);

      for (var en of subscriber.healthCoverage) {
        if (!en.planCoverage)
          continue;
        let dateStart = new Date(en.benefitBeginDate);
        let dateEnd = new Date(en.benefitEndDate);

        if (this.filterType == "Current") {
          if ((en.benefitBeginDate && dateStart > today) || (en.benefitEndDate && dateEnd < cutOffDate))
            continue;
        }
        else if (this.filterType == "Removed") {
          if (!en.benefitEndDate || dateEnd >= cutOffDate)
            continue;
        }
        else if (this.filterType == "OpenEnrolment") {
          if (!en.benefitBeginDate || dateStart <= today)
            continue;
        }

        var eP = '';

        if (!en.benefitEndDate) {
          if (dateStart.getMonth() == 0 && dateStart.getDate() == 1)
            eP = `since ${dateStart.getFullYear()}`
          else
            eP = `since ${getShortDateFormatted(dateStart)}`;
        } else {

          if (dateStart.getMonth() == 0 && dateStart.getDate() == 1 && dateEnd.getMonth() == 11 && dateEnd.getDate() == 31) {
            if (dateEnd.getFullYear() == dateStart.getFullYear())
              eP = `for ${dateStart.getFullYear()}`
            else
              eP = `${dateStart.getFullYear()} until ${dateEnd.getFullYear()}`
          }
          else 
            eP = `${getShortDateFormatted(dateStart)} until ${getShortDateFormatted(dateEnd)}`;
        }



        
        //if (periods.indexOf(eP) == -1)
        coverages.push(`${en.planType||''} ${en.planCoverage} ${en.coverageLevelCode} ${eP}`);
        
      }
    }


    return coverages;
  }


}
