<form class="card mt-2" [formGroup]="form" *ngIf="canEdit && staffInjury">
  <div class="card-body">
    <h4 class="mb-3">Update Staff Injury</h4>
    <div class="row mb-2">
      <div class="col-3">
        <b>Staffs Involved</b>
      </div>
      <div class="col-9">
        <div [innerHTML]="staffInjury.staffInvolved_Names | stringJoin"></div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Date Occurred</b>
      </div>
      <div class="col-9">
        {{staffInjury.dateOcurred| date:'shortDate'}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Location</b>
      </div>
      <div class="col-9">
        <select class="form-control" formControlName="location">
          <option value="Center">Center</option>
          <option value="Community">Community</option>
          <option value="Home">Home</option>
        </select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Notes</b>
      </div>
      <div class="col-9">
        <textarea class="form-control" formControlName="notes"></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Monetary Value</b>
      </div>
      <div class="col-9">
        <input type="number" step="0.01" class="form-control" formControlName="monetaryValue" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Treatment Date</b>
      </div>
      <div class="col-9">
        <input type="date" class="form-control" formControlName="treatmentDate" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Documents</b>
      </div>
      <div class="col-9" *ngIf="!staffInjury.fileName || changingInvoiceFile">
        <input *ngIf="!uploadingInvoice" type="file" #fileInput class="form-control" accept="image/*,.pdf" (change)="uploadInvoiceFile(fileInput)" />
        <div *ngIf="uploadingInvoice">
          <i class="material-icons rotate text-muted">loop</i>
          Uploading...
        </div>
      </div>
      <div class="col-9" *ngIf="!!invoiceFileName && !changingInvoiceFile">
        <button class="btn btn-link" (click)="downloadInvoice()">Download {{invoiceFileName}}</button>
        <button class="btn btn-primary" (click)="changeInvoiceFile()">Change Document File</button>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Had Med Clinic Treatment</b>
      </div>
      <div class="col-9">
        <p-checkbox [binary]="true" formControlName="hadMedClinicTreatment"></p-checkbox>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Had Work Med Treatment</b>
      </div>
      <div class="col-9">
        <p-checkbox [binary]="true" formControlName="hadWorkMedTreatment"></p-checkbox>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        <b>Resolved</b>
      </div>
      <div class="col-9">
        <p-checkbox [binary]="true" formControlName="done"></p-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="mr-0 ml-auto">
        <i *ngIf="updatingStaffInjury" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!updatingStaffInjury" id="btnSaveStaffInjury" class="btn btn-primary mr-2" [class.disabled]="form.invalid" (click)="save()">Save</button>
        <button *ngIf="!updatingStaffInjury" id="btnCancelStaffInjury" class="btn btn-secondary" [class.disabled]="form.invalid" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</form>
