import { createAction, props } from '@ngrx/store';
import { TicketType, CreateTicketTypeModel, UpdateTicketTypeModel, TicketStatus, ViewType, TicketAssignmentFilterModel, TicketTypeJobTitle, TicketTypeRole, TicketTypeUser } from 'src/app/models/ticketTypeModels';
import { JobTitleModel } from 'src/app/models/userProfileModel';

// Load all ticket types
export const loadTicketTypes = createAction('[TicketTypes] Load TicketTypes');

export const loadTicketTypesSuccess = createAction(
  '[TicketTypes] Load TicketTypes Success',
  props<{ ticketTypes: TicketType[] }>()
);

export const loadTicketTypesFailure = createAction(
  '[TicketTypes] Load TicketTypes Failure',
  props<{ error: any }>()
);

// Load a single ticket type
export const loadTicketType = createAction(
  '[TicketTypes] Load TicketType',
  props<{ id: number }>()
);

export const loadTicketTypeSuccess = createAction(
  '[TicketTypes] Load TicketType Success',
  props<{ ticketType: TicketType }>()
);
export const getViewTypes = createAction('[TicketTypes] Load View Types');

export const getViewTypesSuccess = createAction('[TicketTypes] Load View Types Success',
  props<{ viewTypes: ViewType[] }>());

export const loadTicketTypeFailure = createAction(
  '[TicketTypes] Load TicketType Failure',
  props<{ error: any }>()
);

// Create a ticket type
export const createTicketType = createAction(
  '[TicketTypes] Create TicketType',
  props<{ ticketType: CreateTicketTypeModel }>()
);

export const createTicketTypeSuccess = createAction(
  '[TicketTypes] Create TicketType Success',
  props<{ ticketType: TicketType }>()
);

export const createTicketTypeFailure = createAction(
  '[TicketTypes] Create TicketType Failure',
  props<{ error: any }>()
);

// Update a ticket type
export const updateTicketType = createAction(
  '[TicketTypes] Update TicketType',
  props<{ id: number; ticketType: UpdateTicketTypeModel }>()
);

export const updateTicketTypeSuccess = createAction('[TicketTypes] Update TicketType Success');

export const updateTicketTypeFailure = createAction(
  '[TicketTypes] Update TicketType Failure',
  props<{ error: any }>()
);

// Delete a ticket type
export const deleteTicketType = createAction(
  '[TicketTypes] Delete TicketType',
  props<{ id: number }>()
);

export const deleteTicketTypeSuccess = createAction('[TicketTypes] Delete TicketType Success');

export const deleteTicketTypeFailure = createAction(
  '[TicketTypes] Delete TicketType Failure',
  props<{ error: any }>()
);

export const reorderStatuses = createAction('[TicketTypes] Reorder Ticket Type Statuses',
   props<{ id: number, startIndex: number, newIndex: number }>());

export const reorderStatusesSuccess = createAction('[TicketTypes] Reorder Ticket Type Statuses Success',
   props<{ statuses: TicketStatus[] }>());

export const reorderStatusesFailure = createAction('[TicketTypes] Reorder Ticket Type Statuses Failure',
   props<{ error: any }>());

export const filterTicketUserAssignments = createAction('[TicketTypes] Filter User Ticket Assignments',
  props<{ filter: TicketAssignmentFilterModel }>());

export const filterTicketUserAssignmentsSuccess = createAction('[TicketTypes] Filter User Ticket Assignments Success',
  props<{ userAssignments: TicketTypeUser[] }>());

export const filterTicketRoleAssignments = createAction('[TicketTypes] Filter Role Assignments',
  props<{ filter: TicketAssignmentFilterModel }>());

export const filterTicketRoleAssignmentsSuccess = createAction('[TicketTypes] Filter Role Assignments Success',
  props<{ roleAssignments: TicketTypeRole[] }>());

export const filterTicketJobTitleAssignments = createAction('[TicketTypes] Filter Job Title Assignments',
  props<{ filter: TicketAssignmentFilterModel }>());

export const filterTicketJobTitleAssignmentsSuccess = createAction('[TicketTypes] Filter Ticket Job Assignments Success',
  props<{ jobTitleAssignments: TicketTypeJobTitle[] }>());

export const saveTicketUserAssignments = createAction('[TicketTypes] Save User Assignments',
  props<{ users: TicketTypeUser[] }>());

export const saveTicketUserAssignmentsSuccess = createAction('[TicketTypes] Save Ticket User Assignments Success',
  props<{ userAssignments: TicketTypeUser[] }>());
  
export const saveTicketJobTitleAssignments = createAction('[TicketTypes] Save Job Title Assignments',
  props<{ jobTitles: TicketTypeJobTitle[] }>());

export const saveTicketJobTitleAssignmentsSuccess = createAction('[TicketTypes] Save Ticket Job Assignments Success',
  props<{ jobTitleAssignments: TicketTypeJobTitle[] }>());
  
export const saveTicketRoleAssignments = createAction('[TicketTypes] Save Role Assignments',
  props<{ roles: TicketTypeRole[] }>());

export const saveTicketRoleAssignmentsSuccess = createAction('[TicketTypes] Save Ticket Role Assignments Success',
  props<{ roleAssignments: TicketTypeRole[] }>());
