<div class="card mb-2">
    <div class="card-body">
        <i *ngIf="!ticketTypeForm || loadingForm" class="material-icons rotate text-muted">loop</i>
        <div *ngIf="ticketTypeForm" class="position-relative">

            <button class="btn btn-secondary float-right pointer" routerLink="/ticket-types/list">Back</button>
            <h2>{{ isEditMode ? 'Edit' : 'Create New' }} Ticket Type</h2>

            <div class="row">
                <div class="col-12 position-relative">
                    <form [formGroup]="ticketTypeForm" *ngIf="ticketTypeForm">

                        <!-- Ticket Type Name -->
                        <div class="row">
                            <div class="col-12 col-lg-6 ">

                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Ticket Type Name</b>
                                    </div>
                                    <div class="col-8">
                                        <input id="nameInput" type="text" class="form-control mt-2"
                                            formControlName="name" />
                                        <app-validation-message [form]="ticketTypeForm" [path]="'name'" [name]="'name'"
                                            [label]="'Ticket Type Name'"> </app-validation-message>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Description <small>(shown in the widget)</small></b>
                                    </div>
                                    <div class="col-8">
                                        <input id="descriptionInput" type="text" class="form-control mt-2"
                                            formControlName="description" />
                                        <app-validation-message [form]="ticketTypeForm" [path]="'description'" [name]="'description'"
                                            [label]="'Description'"> </app-validation-message>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Color <small>(shown in the widget)</small></b>
                                    </div>
                                    <div class="col-8">
                                        <p-dropdown styleClass="mt-2" [options]="colorOptions"
                                            formControlName="color">
                                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                                <div [class]="'flex align-items-center gap-2 '+selectedOption.value">
                                                    
                                                    <div [class]="'color-square '+selectedOption.value">
                                                    </div>
                                                    <div class="display-inline">{{ selectedOption.label }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-color pTemplate="item">
                                                <div [class]="'flex align-items-center gap-2 '+color.value">
                                                    
                                                    <div [class]="'color-square '+color.value">
                                                    </div>
                                                    <div class="display-inline">{{ color.label }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <app-validation-message [form]="ticketTypeForm" [path]="'color'" [name]="'color'"
                                            [label]="'Color'"> </app-validation-message>
                                    </div>

                                </div>
                            </div>

                            <div class="w-100"></div>

                            <!-- Custom Fields Table -->
                            <div class="col-12 col-lg-8 mt-4">
                                <h4>Custom Fields</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addCustomField()">Add Custom
                                    Field</button>

                                <p-table [value]="customFields.controls" *ngIf="customFields.length > 0"
                                    class="p-datatable-sm">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center">Field Name
                                                <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleFieldNameInfo()"></i>
                                                <div class="text-center info-modal" *ngIf="showFieldNameInfo">
                                                    <span class="m-0 p-0">The field name will show as the label of the field when a user is filling out the ticket.</span>
                                                </div>
                                            </th>
                                            <th class="text-center">Field Type
                                                <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleFieldTypeInfo()"></i>
                                                <div class="text-center info-modal" *ngIf="showFieldTypeInfo">
                                                    <span class="m-0 p-0">Determines if the field is an input, select, date, etc. </span>
                                                </div>
                                            </th>
                                            <th class="text-center">Description
                                                <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleDescriptionInfo()"></i>
                                                <div class="text-center info-modal" *ngIf="showDescriptionInfo">
                                                    <span class="m-0 p-0">The description will show as the placeholder 
                                                         text that will remove when a user clicks into the field to fill it out.
                                                         Only available on text input fields.</span>
                                                </div>
                                            </th>
                                            <th class="text-center">Other settings
                                                <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleOtherSetttingsInfo()"></i>
                                                <div class="text-center info-modal" *ngIf="showOtherSettingsInfo">
                                                    <span class="m-0 p-0">The field name will show as the label of the field when a user is filling out a ticket</span>
                                                </div>
                                            </th>
                                            <th class="text-center">Only Overseers
                                                <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleOnlyManagementInfo()"></i>
                                                <div class="text-center info-modal" *ngIf="showOnlyManagementInfo">
                                                    <span class="m-0 p-0">When checked, only users assigned to oversee tickets will be able to view the field.</span>
                                                </div>
                                            </th>
                                            <th class="text-center" style="width: 120px;">Actions</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-customField let-i="rowIndex">
                                        <tr [formGroup]="customField">
                                            <td>
                                                <input type="text" class="form-control" formControlName="name" />
                                            </td>
                                            <td>
                                                <p-dropdown styleClass="mt-2" [options]="fieldTypesOptions"
                                                    formControlName="fieldTypeId"></p-dropdown>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="customField.get('fieldTypeId').value == 2 || customField.get('fieldTypeId').value == 1">
                                                    <textarea #customFieldDescription class="form-control mt-2" rows="1"  (keyup)="textAreaResize($event)" formControlName="description"></textarea>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="customField.get('fieldTypeId').value == 4 ">
                                                    <span class="font-weight-bold">Field Options</span>
                                                    <textarea class="form-control"
                                                        formControlName="fieldOptions"></textarea>
                                                    <small>Add each option in one line</small>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="customField.get('fieldTypeId').value == 4 || customField.get('fieldTypeId').value == 5 ">
                                                    <div>
                                                        <span class="font-weight-bold mr-2">Allow other</span>
                                                        <p-checkbox binary="true"
                                                            formControlName="allowOther"></p-checkbox>
                                                    </div>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <div class="row justify-content-center align-items-center">
                                                    <p-checkbox binary="true"
                                                    formControlName="showOnlyManagement"></p-checkbox>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="removeCustomField(i)">Remove</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="customFields.length === 0">No custom fields assigned. Add custom fields
                                    using the button above.</div>
                            </div>

                            <!-- Ticket Statuses Table -->
                            <div class="col-12 col-lg-4  mt-4">
                                <h4>Ticket Statuses</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addStatus()">Add
                                    Status</button>
                                                                
                                <div *ngIf="ticketStatuses.length > 0" class="p-datatable-sm">
                                    <div class="row">
                                        <div class="col-4 text-center border background-light d-flex justify-content-center align-items-center">Status Name
                                            <i role="button" class="fas fa-info-circle ml-2" (click)="doToggleStatusInfo()"></i>
                                            <div class="text-center info-modal" *ngIf="showStatusInfo">
                                                <span class="m-0 p-0">
                                                    Statuses here will show in the status field on a ticket as options.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 text-center border background-light justify-content-center align-items-center">Is Final
                                            <i role="button" class="fas fa-info-circle ml-2" (click)="doToggleIsFinalInfo()"></i>
                                            <div class="text-center info-modal" *ngIf="showIsFinalInfo">
                                                <span class="m-0 p-0">
                                                    When selected, this status will tell the system the ticket no longer needs attention.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 text-center border background-light justify-content-center align-items-center">Is Default
                                            <i role="button" class="fas fa-info-circle ml-2" (click)="doToggleIsDefaultInfo()"></i>
                                            <div class="text-center info-modal" *ngIf="showIsDefaultInfo">
                                                <span class="m-0 p-0">
                                                    This status will show when a ticket is first submitted.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-2 text-center border background-light">Actions</div>
                                    </div>
                                    <form [formGroup]="ticketStatuses">
                                        <div cdkDropList (cdkDropListDropped)="drop($event)" >
                                            <div *ngFor="let status of ticketStatuses.controls; let i = index" cdkDrag>
                                                <div class="row pt-2 pb-2 pl-0 pr-0 border" [formGroupName]="i">
                                                    <td class="col-4 d-flex justify-content-center align-items-center">
                                                        <i class="fa fa-bars barsHandle m-0 p-0 mr-3"></i>
                                                        <input type="text" class="form-control" formControlName="name" />
                                                    </td>
                                                    <td class="col-3 border-left">
                                                        <div class="row justify-content-center align-items-center">
                                                            <p-checkbox binary="true" formControlName="isFinal"></p-checkbox>
                                                        </div>
                                                    </td>
                                                    <td class="col-3 border-left">
                                                        <div class="row justify-content-center align-items-center">
                                                            <p-checkbox binary="true" formControlName="isDefault"></p-checkbox>
                                                        </div>
                                                    </td>
                                                    <td class="col-2 border-left">
                                                        <div class="row justify-content-center align-items-center">
                                                            <button type="button" class="btn btn-danger"
                                                                (click)="removeStatus(i)">Remove</button>
                                                        </div>
                                                    </td>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div *ngIf="ticketStatuses.length === 0">No ticket statuses assigned. Add statuses using
                                    the button above.</div>
                            </div>

                            <div class="col-12 col-lg-12 mt-4">
                                <div class="d-flex justify-content-between w-100">
                                    <div>
                                        <button type="button" class="btn mt-3 mb-3" [class.btn-primary]="assignmentTypeUser == true"
                                        [class.btn-secondary]="assignmentTypeUser == false" (click)="selectAssignmentType('User')">Users</button>
                                        <button type="button" class="btn mt-3 mb-3 ml-2" [class.btn-primary]="assignmentTypeTitle == true"
                                        [class.btn-secondary]="assignmentTypeTitle == false" (click)="selectAssignmentType('Title')">Job Titles</button>
                                        <button type="button" class="btn mt-3 mb-3 ml-2"[class.btn-primary]="assignmentTypeRole == true"
                                        [class.btn-secondary]="assignmentTypeRole == false" (click)="selectAssignmentType('Role')">Roles</button>
                                    </div>
                                    <div>
                                        <button type="button" *ngIf="assignmentTypeUser == true" class="btn btn-primary float-right mb-2" (click)="addUser()">Add User</button>
                                        <button type="button" *ngIf="assignmentTypeTitle == true" class="btn btn-primary float-right mb-2" (click)="addJobTitle()">Add Job Title</button>
                                        <button type="button" *ngIf="assignmentTypeRole == true" class="btn btn-primary float-right mb-2" (click)="addRole()">Add Role</button>
                                        <!-- <button type="button" class="btn btn-secondary float-right mb-2 mr-3" (click)="toggleFilter()">{{showFilter == true ? 'Hide ' : 'Show '}}Filter</button> -->
                                    </div>
                                </div>
                                <div class="row mt-1 mb-3" *ngIf="showFilter == true">
                                    <div class="col-6 mb-2">
                                        <div class="row mb-2" *ngIf="assignmentTypeUser == true">
                                            <div class="col-2">
                                                <label class="mt-2">User: </label>
                                            </div>
                                            <div class="col-10">
                                                <app-input-autocomplete
                                                [length]="15"
                                                [type]="'User'"
                                                [(model)]="inputUser"
                                                (modelChange)="setUserID($event)"
                                                [inputClass]="'form-control'"
                                              ></app-input-autocomplete>
                                            </div>
                                        </div>
                                        <div class="row mb-2" *ngIf="assignmentTypeTitle == true">
                                            <div class="col-2">
                                                <label>Title: </label>
                                            </div>
                                            <div class="col-10">
                                                <p-dropdown styleClass="form-control" optionLabel="label" dataKey="value" optionValue="value"
                                                [ngModelOptions]="{standalone: true}" [options]="titleList"
                                                [(ngModel)]="titleFilterID" placeholder="Select title..."></p-dropdown>
                                            </div>
                                        </div>
                                        <div class="row mb-2" *ngIf="assignmentTypeRole == true">
                                            <div class="col-2">
                                                <label>Role: </label>
                                            </div>
                                            <div class="col-10">
                                                <p-dropdown styleClass="form-control"  optionLabel="label" dataKey="value" optionValue="value" 
                                                [ngModelOptions]="{standalone: true}" [options]="roleList"
                                                [(ngModel)]="roleFilterID" placeholder="Select role..."></p-dropdown>
                                            </div>
                                        </div>
                                        <div class="row mb-1 ml-3">
                                            <p-checkbox binary="true"
                                            [(ngModel)]="canBeAssignedFilter" [ngModelOptions]="{standalone: true}"></p-checkbox>
                                            <label class="ml-2">Can be assigned tickets</label>
                                        </div>
                                        <div class="row mb-1 ml-3">
                                            <p-checkbox binary="true"
                                            [(ngModel)]="canCreateFilter" [ngModelOptions]="{standalone: true}"></p-checkbox>
                                            <label class="ml-2">Can create tickets</label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row mb-1">
                                            <p-checkbox binary="true"
                                            [(ngModel)]="receiveNotificationsFilter" [ngModelOptions]="{standalone: true}"></p-checkbox>
                                            <label class="ml-2">Can recieve notifications</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="btn btn-primary float-right mb-2 mr-3" (click)="doFilter()">Filter</button>
                                        <button type="button" class="btn btn-secondary float-right mb-2 mr-3" (click)="clearFilter()">Clear</button>
                                    </div>
                                </div>
                                <div class="row ticket-type-assignments position-relative" *ngIf="isFiltering == false">
                                    <p-table [value]="users.controls" *ngIf="users.length > 0 && assignmentTypeUser == true" class="p-datatable-sm">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">User
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleAssignmentInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showAssignmentInfo">
                                                        <span class="m-0 p-0">Please note that if a user has a role or job title that is assigned with higher level permissions
                                                             the higher level permissions will be enforced. This is applicable accross the board, Whichever assignment type has 
                                                             the higher level of permissions will be enforced.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Can Create Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleCanCreateInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showCanCreateInfo">
                                                        <span class="m-0 p-0">Those assigned will only be allowed 
                                                            to create tickets, make comments, and upload attachments to their created tickets.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Can Manage Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleCanManageInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showCanManageInfo">
                                                        <span class="m-0 p-0">Those assigned will be able to create 
                                                            new tickets and make comments and upload attachments to 
                                                            the tickets they are allowed to view.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Overseer of Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleOverseerInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showOverseerInfo">
                                                        <span class="m-0 p-0">Those assigned will have full access to manage all aspects of tickets</span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Receive Notifications
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleNotificationsInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showNotificationsInfo">
                                                        <span class="m-0 p-0">Those assigned will receive a notification based on their system 
                                                            preferences regarding new or updated tickets. </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">View
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleViewInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showViewInfo">
                                                        <span class="m-0 p-0">Those assigned will determine what tickets 
                                                            a user can and cannot view based on the view option selected.</span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-user let-i="rowIndex">
                                            <tr [formGroup]="user">
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="usersList"
                                                        formControlName="userID" placeholder="Select user..."></p-dropdown>

                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canCreateTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canManageTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="isOverseer"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="doSendTicketNotifications"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="viewList"
                                                        formControlName="viewTypeID" placeholder="Select View Type..."></p-dropdown>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <button type="button" class="btn btn-danger ml-2"
                                                            (click)="removeUser(i)">Remove</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <!-- No Users Message -->
                                    <div *ngIf="users.length === 0 && assignmentTypeUser == true">No users assigned. Add users using the button above.
                                    </div>

                                    
                                    <p-table [value]="roles.controls" *ngIf="roles.length > 0 && assignmentTypeRole == true" class="p-datatable-sm position-relative">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">Role
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleAssignmentInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showAssignmentInfo">
                                                        <span class="m-0 p-0">Please note that if a user has a role or job title that is assigned with higher level permissions
                                                             the higher level permissions will be enforced. This is applicable accross the board, Whichever assignment type has 
                                                             the higher level of permissions will be enforced.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Can Create Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleCanCreateInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showCanCreateInfo">
                                                        <span class="m-0 p-0">Those assigned will be able to create tickets only.
                                                             they will not be able to edit a ticket after submission but will be able to comment and upload
                                                             attachments for tickets that they created.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Can Manage Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleCanManageInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showCanManageInfo">
                                                        <span class="m-0 p-0">Those assigned will be able to create and view tickets.
                                                             they will not be able to edit other tickets nor their own after submission but will be able to comment and upload
                                                             attachments for tickets they can view.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Overseer of Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleOverseerInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showOverseerInfo">
                                                        <span class="m-0 p-0">Those assigned will have full access to manage all aspects of tickets</span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Receive Notifications
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleNotificationsInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showNotificationsInfo">
                                                        <span class="m-0 p-0">Those assigned will recieve (based on settings in their profile) text and/or email notifications
                                                             when one of their tickets is updated. Overseers will receive notifications when a new ticket is submitted or
                                                              a ticket they are assigned to is updated </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">View
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleViewInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showViewInfo">
                                                        <span class="m-0 p-0">Those assigned will have access to the assigned view below. 
                                                             Other permissions and assignments will still apply</span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-role let-i="rowIndex">
                                            <tr [formGroup]="role">
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="roleList"
                                                        formControlName="roleID" placeholder="Select role..."></p-dropdown>

                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canCreateTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canManageTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="isOverseer"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="doSendTicketNotifications"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="viewList"
                                                        formControlName="viewTypeID" placeholder="Select View Type..."></p-dropdown>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <button type="button" class="btn btn-danger ml-2"
                                                            (click)="removeRole(i)">Remove</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <!-- No Users Message -->
                                    <div *ngIf="roles.length === 0 && assignmentTypeRole == true">No roles assigned. Add roles using the button above.
                                    </div>

                                    
                                    <p-table [value]="jobTitles.controls" *ngIf="jobTitles != null && jobTitles.length > 0 && assignmentTypeTitle == true" class="p-datatable-sm">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">Job Title
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleAssignmentInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showAssignmentInfo">
                                                        <span class="m-0 p-0">Please note that if a user has a role or job title that is assigned with higher level permissions
                                                             the higher level permissions will be enforced. This is applicable accross the board, Whichever assignment type has 
                                                             the higher level of permissions will be enforced.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Can Create Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleCanCreateInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showCanCreateInfo">
                                                        <span class="m-0 p-0">Those assigned will be able to create tickets only.
                                                             they will not be able to edit a ticket after submission but will be able to comment and upload
                                                             attachments for tickets that they created.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Can Manage Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleCanManageInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showCanManageInfo">
                                                        <span class="m-0 p-0">Those assigned will be able to create and view tickets.
                                                             they will not be able to edit other tickets nor their own after submission but will be able to comment and upload
                                                             attachments for tickets they can view.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Overseer of Tickets
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleOverseerInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showOverseerInfo">
                                                        <span class="m-0 p-0">Those assigned will have full access to manage all aspects of tickets</span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Receive Notifications
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleNotificationsInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showNotificationsInfo">
                                                        <span class="m-0 p-0">Those assigned will recieve (based on settings in their profile) text and/or email notifications
                                                             when one of their tickets is updated. Overseers will receive notifications when a new ticket is submitted or
                                                              a ticket they are assigned to is updated </span>
                                                    </div>
                                                </th>
                                                <th class="text-center">View
                                                    <i role="button" class="ml-2 fas fa-info-circle" (click)="doToggleViewInfo()"></i>
                                                    <div class="text-center info-modal" *ngIf="showViewInfo">
                                                        <span class="m-0 p-0">Those assigned will have access to the assigned view below. 
                                                             Other permissions and assignments will still apply</span>
                                                    </div>
                                                </th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-jobTitle let-i="rowIndex">
                                            <tr [formGroup]="jobTitle">
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="titleList"
                                                        formControlName="jobTitleID" placeholder="Select job title..."></p-dropdown>

                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canCreateTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="canManageTickets"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="isOverseer"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <p-checkbox binary="true"
                                                        formControlName="doSendTicketNotifications"></p-checkbox>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p-dropdown styleClass="form-control mt-2" [options]="viewList"
                                                        formControlName="viewTypeID" placeholder="Select View Type..."></p-dropdown>
                                                </td>
                                                <td>
                                                    <div class="row justify-content-center align-items-center">
                                                        <button type="button" class="btn btn-danger ml-2"
                                                            (click)="removeJobTitle(i)">Remove</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <!-- No Users Message -->
                                    <div *ngIf="jobTitles.length === 0 && assignmentTypeTitle == true">No job titles assigned. Add job titles using the button above.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start align-items-center">
                            <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="ticketTypeForm.invalid"
                               (click)="onSubmit()">Save</button>
                            <button class="btn btn-secondary float-right pointer float-left mt-3 ml-3" routerLink="/ticket-types/list">Back</button>
                            <i *ngIf="!ticketTypeForm || loadingForm" class="material-icons rotate text-muted">loop</i>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>