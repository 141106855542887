
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateTicketTypeModel, TicketAssignmentFilterModel, TicketStatus, TicketType, TicketTypeJobTitle, TicketTypeRole, TicketTypeUser, UpdateTicketTypeModel, ViewType } from 'src/app/models/ticketTypeModels'; // Assuming the models exist

@Injectable({
  providedIn: 'root'
})
export class TicketTypesService {
  private apiUrl = `${environment.apiServer.webApiURL}/tickettype`;

  constructor(private http: HttpClient) {}

  
  getAllTicketTypes(): Observable<TicketType[]> {
    return this.http.get<TicketType[]>(this.apiUrl);
  }

  getTicketTypeById(id: number): Observable<TicketType> {
    return this.http.get<TicketType>(`${this.apiUrl}/${id}`);
  }

  createTicketType(ticketType: CreateTicketTypeModel): Observable<TicketType> {
    return this.http.post<TicketType>(this.apiUrl, ticketType);
  }

  updateTicketType(id: number, ticketType: UpdateTicketTypeModel): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${id}`, ticketType);
  }

  deleteTicketType(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  reorderStatuses(id: number, startIndex: number, newIndex: number): Observable<TicketStatus[]> {
    return this.http.get<TicketStatus[]>(`${this.apiUrl}/ReorderTicketTypeStatuses/?id=${id}&startIndex=${startIndex}&newIndex=${newIndex}`);
  }

  getViewTypes(): Observable<ViewType[]> {
    return this.http.get<ViewType[]>(`${this.apiUrl}/LoadViewTypes`);
  }

  filterTicketUserAssignments(filter: TicketAssignmentFilterModel): Observable<TicketTypeUser[]> {
    return this.http.post<TicketTypeUser[]>(`${this.apiUrl}/filterticketuserassignments`, filter);
  }
  
  filterTicketRoleAssignments(filter: TicketAssignmentFilterModel): Observable<TicketTypeRole[]> {
    return this.http.post<TicketTypeRole[]>(`${this.apiUrl}/filterticketroleassignments`, filter);
  }
  
  filterTicketJobTitleAssignments(filter: TicketAssignmentFilterModel): Observable<TicketTypeJobTitle[]> {
    return this.http.post<TicketTypeJobTitle[]>(`${this.apiUrl}/filterticketjobtitleassignments`, filter);
  }

  saveTicketUserAssignments(users: TicketTypeUser[]): Observable<TicketTypeUser[]> {
    return this.http.post<TicketTypeUser[]>(`${this.apiUrl}/saveticketuserassignments`, users);
  }
  
  saveTicketRoleAssignments(roles: TicketTypeRole[]): Observable<TicketTypeRole[]> {
    return this.http.post<TicketTypeRole[]>(`${this.apiUrl}/saveticketroleassignments`, roles);
  }
  
  saveTicketJobTitleAssignments(jobTitles: TicketTypeJobTitle[]): Observable<TicketTypeJobTitle[]> {
    return this.http.post<TicketTypeJobTitle[]>(`${this.apiUrl}/saveticketjobtitleassignments`, jobTitles);
  }
}
