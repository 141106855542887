import { UserModel } from './userModel';
export interface FBFormModel {
  id?: number;
  baseDocFileID?: number;
  baseDocHTML?: string;
  baseSummaryDocHTML?: string;
  formTypeID?: number;
  name?: string;
  description?: string;
  inactive?: boolean;
  createdDate?: string;
  baseDocFile?: FBDocFileModel;
  formType?: FBFormTypeModel;
  formFields?: FBFormFieldModel[];
  formFieldGroups?: FBFormFieldGroupModel[];

  required?: boolean;
  requireHRSignature?: boolean;
  isAutoAssignable?: boolean;
  reAssignAfterDays?: number;
  shouldAssignAsDefault?: boolean;
  isCredentialDoc?: boolean;
  requiredForAuthorizationCategoryIDs?: string;
  isForAdult?: boolean;
  isForChild?: boolean;
  isForNormalClient?: boolean;
  isForEmployeeClient?: boolean;
  dueInDays?: number;
  assignAfterOrientation?: boolean;
  assignAfterNPI?: boolean;
  showSignature?: boolean;
  jobTitleIDs?: number[];
  insuranceProviderIDs?: number[];
  otherJobPositions?: string;
  clientOrEmployee?: string;
  neverShowOnClientPortal?: boolean;
  isI9?: boolean;
  isInternForm?: boolean;
  canOptOut?: boolean;
  showOnClientFormsReport?: boolean;
  noSignatureRequired?: boolean;

  formLanguages?: FBFormLanguageModel[];
}

export interface FBFormFieldSection {
  entryNumber: number;
  readOnly: boolean;
  formFields: FBFormFieldModel[];
}

export interface FBFormFieldModel {
  entryNumber?: number;
  formControlIdentifier?: string;
  id?: number;
  fieldName?: string;
  fieldDescription?: string;
  fieldTypeID?: number;
  fieldSize?: number;
  minLength?: number;
  fieldGroup?: string;
  fieldOrder?: number;
  fieldOptions?: string;
  fieldOptionsList?: any[];
  fieldWidthRatio?: number;
  isRequired?: boolean;
  needApproval?: boolean;
  pDFFieldName?: string;
  inactive?: boolean;
  createdDate?: string;
  formFieldType?: FBFormFieldTypeModel;
  allowOther?: boolean;
  isEncrypted: boolean;
  readOnly: boolean;
  relatedFormField?: FBFormFieldModel;
  requiredIfField?: FBFormFieldModel;
  relatedFormFieldID?: number;
  sectionFormFieldID?: number;
  showIfRelatedAnswerIs?: string;
  requiredIfFieldID?: number;
  requiredIfFieldAnswerIs?: string;
  repeat?: boolean;
  minItems?: number;
  sectionFields?: FBFormFieldModel[];
  sections?: FBFormFieldSection[];

  mappingColumnGet?: number;
  mappingColumnsSet: [];
  optionsTable?: string;

  isHRField?: boolean;
  numbered?: boolean;

  minValue?: number;
  maxValue?: number;
  
  calculatedRelatedFieldIDs?: string;
  calculatedRelatedFieldIDsArray?: number[];
  hideLabel?: boolean;
  tooltip?: string;
  defaultText?: string;
  showAsDropdown?: boolean;
  hasGlobalCounter?: boolean;

  fillableBeforeSending?: boolean;
  formFieldGroupID?: number;
  formFieldGroup?: FBFormFieldGroupModel;

  sectionID?: number;
  sectionName?: string;

  formFieldLanguages?: FBFormFieldLanguageModel[];

  //--LOCAL
  localID?: number;
  opened?: boolean;
}


export interface FBFormFieldGroupModel{
  id?: number;
  groupName?: string;
  order?: number;
  tooltip?: string;

  formFieldGroupLanguages?: FBFormFieldGroupLanguageModel[];

  localID?: number;
}

export interface FBMappingColumnModel {
  mappingColumnID: number;
  formFieldID?: number;
  formTypeIDs: number[];
  friendlyName: string;
  table: string;
  column: string;
  isGet?: boolean;
  isSet?: boolean;
}
export interface FBDocFileModel {
  id?: number;
  fileDate?: string;
  fileName: string;
  fileType: string;
  fileAwsBucket: string;
  fileAwsKey: string;
  fileSize?: number;
  base64Data?: any;
}

export interface FBFormTypeModel {
  id?: number;
  formType: string;
}

export interface FBFormFieldValueModel {
  sectionFormFieldID?: number;
  id?: number;
  formSubmissionID?: number;
  formFieldID?: number | string;
  formField?: FBFormFieldModel;
  value: string;
  createdDate?: string;
  fileName?: string;
  fileDownloadUrl?: string;
  entryNumber?: number;
  readOnly?: boolean;
}

export interface FBFormFieldTypeModel {
  id?: number;
  formFieldType: string;
}

export interface FBFormAssignmentModel {
  id?: number;
  userID?: number;
  formID?: number;
  formSubmissionID?: number;
  user?: UserModel;
  form?: FBFormModel;
  formSubmission?: FBFormSubmissionModel;
  isRequired?: boolean;
  dueDate?: string;
  expirationDate?: string;

  userIDs?: number[];
  inquiryIDs?: number[];

  internApplicationId?: number;
  archived?: boolean;
  signatureRequired?: boolean;
  isInternDecisionForm?: boolean;
  canOptOut?: boolean;

  rejectionDate?: Date;
  rejectionReason?: string;
  createdDate?: Date;
}

export interface FBFormSubmissionModel {
  id?: number;
  formID?: number;
  userID?: number;
  generatedDocFileID?: number;
  documentToSignID?: number;
  createdDate?: string;
  fbFormFieldValues?: FBFormFieldValueModel[];
  fbForm?: FBFormModel;
  updatedDate?: string;
  signedDate?: string;
  optOutDate?: string;
  signedByHRDate?: string;
  languageID?: number;
}

export interface FBFormRoleAssignmentModel {
  id: number;
  formAssignRoles: number[];
  formAssignNotInRoles: number[];
  formRequiredRoles: number[];
  formRequiredNotInRoles: number[];
}

export interface FBFormJobTitleAssignmentModel {
  id: number;
  formAssignJobTitles: number[];
  formAssignNotJobTitles: number[];
  formRequiredJobTitles: number[];
  formRequiredNotJobTitles: number[];
}

export interface InquirySimpleModel {
  inquiryID?: number;
  patientID?: number;
  name: string;

}

export interface UsersWithFormsModel {
  inquiryID?: number;
  userID?: number;
  usersName: string;
  assignedForm: FormSignedRequiredModel[];
  unSignedRequiredCount?: number;
}

export interface FormSignedRequiredModel {
  formName: string;
  signed: boolean;
  required: boolean;
}

export interface SimpleFormsListModel {
  id: number;
  name: string;
  formTypeID: number;
  formType: string;
  inactive: boolean;
  clientOrEmployee: string;
  isInternForm: boolean;
  hidden: boolean;
}



export let FBFieldType = {
  Text: 1,
  BigText: 2,
  Phone: 3,
  Email: 4,
  Number: 5,
  Date: 6,
  OneOption: 7,
  ManyOptions: 8,
  File: 9,
  Section: 10,
  Age: 11,
  Label: 12,
  Title: 13,
  Sum: 14,
  Average: 15,
  Map: 16,
  PercentageOfMax: 17,
  LastValue: 18,
  LastValueFieldName: 19,
  TextWithValue: 20,
};


export interface FBFormLanguageModel {
  id?: number;
  baseDocFileID?: number;
  baseDocHTML?: string;
  baseSummaryDocHTML?: string;
  name?: string;
  description?: string;
  baseDocFile?: FBDocFileModel;
  languageID?: number;
  formID?: number;
  languageName?: string;
  languageCode?: string;
}

export interface FBFormFieldLanguageModel {
  id?: number;
  fieldDescription?: string;
  fieldOptions?: string;
  tooltip?: string;
  defaultText?: string;
  languageID?: number;
  formFieldID?: number;
}


export interface FBFormFieldGroupLanguageModel{
  id?: number;
  groupName?: string;
  tooltip?: string;
  languageID?: number;
  formFieldGroupID?: number;
}
