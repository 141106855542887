import { Action, createReducer, on } from '@ngrx/store';
import { TicketType, TicketTypeJobTitle, TicketTypeRole, TicketTypeUser, ViewType } from 'src/app/models/ticketTypeModels';
import { loadTicketTypes, loadTicketTypesSuccess, 
  loadTicketTypesFailure, loadTicketType, loadTicketTypeSuccess,
  loadTicketTypeFailure, createTicketType, createTicketTypeSuccess,
  createTicketTypeFailure, updateTicketType, updateTicketTypeSuccess, 
  updateTicketTypeFailure, deleteTicketType, deleteTicketTypeSuccess,
  deleteTicketTypeFailure, reorderStatusesSuccess, getViewTypesSuccess, 
  filterTicketUserAssignmentsSuccess, 
  filterTicketRoleAssignmentsSuccess,
  filterTicketJobTitleAssignmentsSuccess,
  saveTicketUserAssignmentsSuccess,
  saveTicketRoleAssignmentsSuccess,
  saveTicketJobTitleAssignmentsSuccess} from './ticket-types.actions';

export interface TicketTypesState {
  ticketTypes: TicketType[];
  viewTypes: ViewType[];
  selectedTicketType: TicketType | null;
  error: any;
  loading: boolean;
  userAssignments: TicketTypeUser[]; 
  roleAssignments: TicketTypeRole[]; 
  jobTitleAssignments: TicketTypeJobTitle[]; 
}

export const initialState: TicketTypesState = {
  ticketTypes: [],
  viewTypes: [],
  selectedTicketType: null,
  error: null,
  loading: false,
  userAssignments: [],
  roleAssignments: [],
  jobTitleAssignments: [], 
};

export const ticketTypesReducer = createReducer(
  initialState,
  on(loadTicketTypes, state => ({
    ...state,
    loading: true,
  })),
  on(loadTicketTypesSuccess, (state, { ticketTypes }) => ({
    ...state,
    ticketTypes,
    loading: false,
  })),
  on(loadTicketTypesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(loadTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(loadTicketTypeSuccess, (state, { ticketType }) => ({
    ...state,
    selectedTicketType: ticketType,
    loading: false,
  })),
  on(loadTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(createTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(createTicketTypeSuccess, (state, { ticketType }) => ({
    ...state,
    ticketTypes: [...state.ticketTypes, ticketType],
    loading: false,
  })),
  on(createTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(updateTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(updateTicketTypeSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(updateTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(deleteTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(deleteTicketTypeSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(deleteTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(getViewTypesSuccess, (state, { viewTypes }) => ({
    ...state,
    viewTypes,
    loading: false,
  })),
  on(filterTicketUserAssignmentsSuccess, (state, { userAssignments }) => ({
    ...state,
    userAssignments,
    loading: false,
  })),
  on(filterTicketRoleAssignmentsSuccess, (state, { roleAssignments }) => ({
    ...state,
    roleAssignments,
    loading: false,
  })),
  on(filterTicketJobTitleAssignmentsSuccess, (state, { jobTitleAssignments }) => ({
    ...state,
    jobTitleAssignments,
    loading: false,
  })),
  on(saveTicketUserAssignmentsSuccess, (state, { userAssignments }) => ({
    ...state,
    userAssignments,
    loading: false,
  })),
  on(saveTicketRoleAssignmentsSuccess, (state, { roleAssignments }) => ({
    ...state,
    roleAssignments,
    loading: false,
  })),
  on(saveTicketJobTitleAssignmentsSuccess, (state, { jobTitleAssignments }) => ({
    ...state,
    jobTitleAssignments,
    loading: false,
  })),
);

export function ticketTypesStateReducerFunc(state: TicketTypesState | undefined, action: Action) {
  return ticketTypesReducer(state, action);
}